import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding-left: 25px;
  padding-right: 25px; 
  
  @media only screen and (min-width: 501px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

export {
  Container
}
