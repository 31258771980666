import styled from "styled-components";
import {Container} from "../shared";
import linkedin from './linkedin.svg';

const Root = styled.div`
  height: 365px;
  background-color: #424242;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  z-index: 1000;
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;
`;

const TextSmall = styled.div`
  z-index: 1000;
  font-size: 16px;
  color: white;
  margin-bottom: 20px;
`;

const Panel1 = () => (
  <Root>
    <Container>
      <Text>
        Contact us
      </Text>
      <Text style={{color: '#eb0b1a'}}>
        hello@burnsred.com.au
      </Text>
      <TextSmall>
        Level 4, 126 Wellington Parade, East Melbourne VIC 3002
      </TextSmall>
      <TextSmall style={{color: '#eb0b1a'}}>
        <a href={'https://goo.gl/maps/i7oR2RbjjvovnwqHA'} style={{color: '#eb0b1a', textDecoration: 'none'}}>
          Get directions
        </a>
      </TextSmall>
      <TextSmall style={{color: '#eb0b1a'}}>
        <a href={'https://www.linkedin.com/company/burnsred/'}>
          <img src={linkedin} width={27} />
        </a>
      </TextSmall>
    </Container>
  </Root>
)

export default Panel1;
