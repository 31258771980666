import React from 'react';
import styled from "styled-components";
import {Container} from "../shared";
import initScripts from './scripts';
import {Link} from "react-scroll";


const Root = styled.div`
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroText = styled.div`
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  color: #eb0b1a;
  
  @media only screen and (min-width: 501px) {
    text-align: center;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 75px;
  left: 5px;
  right: 5px;
  bottom: 5px;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 17px;
  font-weight: 600;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: #eb0b1a;
`;

const Panel1 = () => {
  React.useEffect(() => {
    initScripts();
  }, [])

  return (
    <Root>
      <HeroBackground id="particles-js" />
      <Container>
        <HeroText>
          Solving tomorrow’s problems<br/>
          for the world’s biggest brands
        </HeroText>
        <div style={{ marginTop: 60, display: 'flex', justifyContent: 'center'}}>
          <Link to="join" smooth duration={1000}>
            <Button>
              We are recruiting!
            </Button>
          </Link>
        </div>
      </Container>
    </Root>
  )
};

export default Panel1;
