import styled from "styled-components";
import bgImg from './commoditisation.jpg';

const Root = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  color: #f7fae8;
  z-index: 1000;
  max-width: 695px;
  font-size: 30px;
  font-weight: bold;

  @media only screen and (min-width: 501px) {
    font-size: 50px;
    text-align: center;
  }
`;

const Image = styled.img`
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;

  animation: 120s ease 0s normal none infinite running zoomin;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d1d1d1;
  opacity: 0.15;
`

const Panel3 = () => (
  <Root>
    <Image src={bgImg} />
    <Text>
      Commoditisation of technology is resulting in
      <span style={{color: '#84f4b1'}}> new ways </span>
      of delivering software services to the enterprise world.
    </Text>
    <Overlay />
  </Root>
)

export default Panel3;
