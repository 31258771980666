import {useState, useEffect} from 'react';
import styled from 'styled-components';
import Drawer from 'react-modern-drawer';
import { Link, Element } from 'react-scroll'

import { useMatomo } from '@datapunt/matomo-tracker-react'

import crossIcon from './cross-icon.svg';
import logoSmall from './logo-small.png';

import Header from "./components/Header";
import Panel1 from "./components/Panel1";
import Banner from "./components/Banner";
import Panel2 from "./components/Panel2";
import Panel3 from "./components/Panel3";
import Panel4 from "./components/Panel4";
import Panel5 from "./components/Panel5";
import Panel6 from "./components/Panel6";
import Panel7 from "./components/Panel7";
import Panel8 from "./components/Panel8";
import Footer from "./components/Footer";

import './App.css';

const Sidebar = styled.div`
  padding: 125px 35px 54px;
  position: relative;

  > img {
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
  }

  > div {
    position: absolute;
    top: 35px;
    left: 35px;
    cursor: pointer;
  }

  > a {
    display: block;
    cursor: pointer;
    font-size: 25px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all 0.3s ease;

    &:hover {
      color: #eb0b1a;
    }
  }
`;

function App() {
  const [isOpen, setIsOpen] = useState(false)
  const { trackPageView, trackEvent } = useMatomo()

  const toggleDrawer = (section) => {
    trackEvent({ category: section, action: 'click-event' })
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    trackPageView()
  }, [])

  return (
    <div>
      <Header onClickMenu={toggleDrawer} />
      <Element name={'top'} />
      <Panel1 />
      <Panel2 />
      <Panel3 />
      <Element name={'approach'} />
      <Panel4 />
      <Element name={'partners'} />
      <Panel5 />
      <Panel6 />
      <Panel7 />
      <Element name={'join'} />
      <Panel8 />
      <Element name={'contact'} />
      <Footer />
      <Banner />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='right'
        overlayOpacity={0.8}
        overlayColor={'white'}
        zIndex={11000}
        style={{
          width: 320
        }}
      >
        <Sidebar>
          <div>
            <Link to="top" smooth duration={1000} onClick={() => toggleDrawer('close')}>
              <img src={logoSmall} width={40} style={{left: 35}} />
            </Link>
          </div>
          <img src={crossIcon} width={32} onClick={() => toggleDrawer('close')} />
          <Link to="approach" smooth duration={1000} onClick={() => toggleDrawer('approach')}>
            Approach
          </Link>
          <Link to="partners" smooth duration={1000} onClick={() => toggleDrawer('partners')}>
            Partners
          </Link>
          <Link to="join" smooth duration={1000} onClick={() => toggleDrawer('join')}>
            Join
          </Link>
          <Link to="contact" smooth duration={1000} onClick={() => toggleDrawer('contact')}>
            Contact
          </Link>
        </Sidebar>
      </Drawer>
    </div>
  );
}

export default App;
