import styled from "styled-components";
import evolveIcon from './evolve-icon.png';
import icon1 from './django@2x.png';
import icon2 from './python-logo@2x.png';
import icon3 from './group@2x.png';
import icon4 from './django-rest@2x.png';
import icon5 from './ubuntu@2x.png';
import icon6 from './react@2x.png';
import icon7 from './html@2x.png';
import icon8 from './css@2x.png';
import icon9 from './javascript@2x.png';
import icon10 from './docker@2x.png';
import {Container} from "../shared";


const Root = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  color: #bbe5e3;
  z-index: 1000;
  max-width: 806px;
  font-size: 32px;
  font-weight: bold;
  line-height: 0.96;
  
  @media only screen and (min-width: 501px) {
    font-size: 48px;
  }
`;

const SmallText = styled.div`
  color: #d8d8d8;
  z-index: 1000;
  max-width: 600px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 60px;
  line-height: 1.6;
`;

const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
`;

const ImagePanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 70px;
  flex-wrap: wrap;
  
  img {
    zoom: 50%;
    margin-right: 40px;
    margin-bottom: 40px;
  }
`;

const ImageOuter = styled.div`
  z-index: 1000;
  position: relative;

  img {
    max-width: 240px;
    
    @media only screen and (min-width: 501px) {
      max-width: 514px;
    }
  }
`;

const ContentOuter = styled.div`
  z-index: 1000;
  position: relative;
  
  @media only screen and (min-width: 501px) {
    align-self: center;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  width: 100%;
  flex-direction: column;
  
  @media only screen and (min-width: 501px) {
    padding-left: 100px;
  }
`;

const Panel3 = () => (
  <Root>
    <Video autoPlay muted loop style={{zIndex: 500}}>
      <source src="assets/connections.mp4" type="video/mp4" />
    </Video>
    <StyledContainer>
      <ImageOuter>
        <img src={evolveIcon} style={{marginBottom: 36}} />
      </ImageOuter>
      <ContentOuter>
        <Text>
          An open and extensible software development environment that offers incredible levels of integration
          with legacy systems.
        </Text>
        <SmallText>
          The Evolve Platform is a suite of purpose built custom software delivery tools that allow us to design and
          build complex, enterprise grade applications quickly with no compromises on security or maintainability.
        </SmallText>
        <ImagePanel>
          <img src={icon1} />
          <img src={icon2} />
          <img src={icon3} />
          <img src={icon4} />
          <img src={icon5} />
          <img src={icon6} />
          <img src={icon7} />
          <img src={icon8} />
          <img src={icon9} />
          <img src={icon10} />
        </ImagePanel>
      </ContentOuter>
    </StyledContainer>
  </Root>
)

export default Panel3;
