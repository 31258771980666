import styled from "styled-components";
import {Container} from "../shared";
import icon1 from './afl@2x.png';
import icon2 from './logo-amp@2x.png';
import icon3 from './logo-ansvar@2x.png';
import icon4 from './aon@2x.png';
import icon5 from './logo-asic@2x.png';
import icon6 from './logo-bh-law@2x.png';
import icon7 from './logo-bendigo@2x.png';
import icon8 from './logo-bhp@2x.png';
import icon9 from './logo_watercorp@2.png';
import icon10 from './logo-bp@2x.png';
import icon11 from './logo-cigna@2x.png';
import icon12 from './logo-clough@2x.png';
import icon13 from './logo-coles@2x.png';
import icon14 from './logo-coors@2x.png';
import icon15 from './logo-freshfields@2x.png';
import icon16 from './logo-ge@2x.png';
import icon17 from './logo-genesis-care@2x.png';
import icon18 from './logo-heinz@2x.png';
import icon19 from './logo-mutualtrust@2x.png';
import icon20 from './logo-perpetual@2x.png';
import icon21 from './logo-woodside.png';
import icon22 from './logo-roy-morgan@2x.png';
import icon23 from './logo-simplot@2x.png';
import icon24 from './logo-slater-gordon@2x.png';
import icon25 from './logo_advara.png';

const Root = styled.div`
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 100px;
  
  display: flex;
  justify-content: center;
  background-color: #d8d8d8;
    
  @media only screen and (min-width: 501px) {
    padding-top: 150px;
  }
`;


const Text = styled.div`
  z-index: 1000;
  font-size: 50px;
  font-weight: bold;
`;

const ImagePanel = styled.div`
  z-index: 1000;
  margin-top: 30px;
  max-width: 840px;
  width: 336px;

  > img {
    width: 168px;
  }
   
  @media only screen and (min-width: 501px) {
    margin-top: 130px;
    width: auto;
  }
`;

const Panel1 = () => (
  <Root>
    <Container style={{display: 'flex', flexDirection: 'column'}}>
      <Text>
        We have
        <span style={{color: 'white'}}> amazing </span>
        partners.
      </Text>
      <ImagePanel style={{alignSelf: 'center'}}>
        <img src={icon1} alt="AFL Players" />
        <img src={icon2} alt="AMP" />
        <img src={icon3} alt="Ansvar" />
        <img src={icon4} alt="AON" />
        <img src={icon5} alt="ASIC" />
        <img src={icon6} alt="Belbridge Hague Law" />
        <img src={icon7} alt="Bendigo Bank" />
        <img src={icon8} alt="BHP" />
        <img src={icon9} alt="WA Water Corporation" />
        <img src={icon10} alt="BP" />
        <img src={icon11} alt="Cigna" />
        <img src={icon12} alt="Clough" />
        <img src={icon13} alt="Coles" />
        <img src={icon14} alt="Corrs Chambers Westgath" />
        <img src={icon15} alt="Freshfields" />
        <img src={icon16} alt="GE" />
        <img src={icon17} alt="GenesisCare" />
        <img src={icon18} alt="Heinz" />
        <img src={icon19} alt="Mutual Trust" />
        <img src={icon20} alt="Perpetual" />
        <img src={icon21} alt="Woodside" />
        <img src={icon22} alt="Roy Morgan" />
        <img src={icon23} alt="Simplot" />
        <img src={icon24} alt="Slater & Gordon" />
        <img src={icon25} alt="Advara HeartCare" />
      </ImagePanel>
    </Container>
  </Root>
)

export default Panel1;
