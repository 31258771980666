import styled from "styled-components";
import {Container} from "../shared";
import icon from './flas-icons.gif';
import { Textfit } from 'react-textfit';

const Root = styled.div`
  position: relative;
  min-height: 50vh;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  
  @media only screen and (min-width: 501px) {
    height: 100vh;
  }
`;

const HeroText = styled.div`
  font-family: Intro, "Helvetica Neue", Helvetica, serif;
  font-weight: 900;
  line-height: 82%;
`;

const HalfPanel = styled.div`
  width: 100%;
  z-index: 1000;
  padding-right: 25px;
  
  @media only screen and (min-width: 501px) {
    padding-right: 72px;
    width: 50%;
  }
`;

const RightPanel = styled.div`
  position: absolute;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 150%;
  flex: 1;
  background-color: #eb0b1a;
  display: none;
  
  @media only screen and (min-width: 501px) {
    display: block;
  }
`;

const RightPanelUpper = styled.div`
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  z-index: 1000;
  display: none;
  
  @media only screen and (min-width: 501px) {
    display: flex;
  }
`;

const MobilePanel = styled(Root)`
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #eb0b1a;
  
  @media only screen and (min-width: 501px) {
    display: none;
  }
`;

const Panel2 = () => (<>
  <Root>
    <Container>
      <div style={{display: 'flex'}}>
        <HalfPanel>
          <Textfit max={92} mode="single" style={{maxWidth: '100%'}}>
            <HeroText style={{color: '#eb0b1a'}}>
              BURNSRED
            </HeroText>
            <HeroText>
              BESPOKE<br/>
              SOFTWARE<br/>
              INNOVATION
            </HeroText>
          </Textfit>
        </HalfPanel>
      </div>
    </Container>
    <RightPanel>
    </RightPanel>
    <RightPanelUpper>
      <img src={icon} />
    </RightPanelUpper>
  </Root>
  <MobilePanel>
    <img src={icon} />
  </MobilePanel>
</>)

export default Panel2;
