import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container} from "../shared";
import lottie from 'lottie-web';
import {StickyContainer, Sticky} from 'react-sticky';
import brains from './icn-brains@2x.png';
import brainsLg from './icn-brains-lg@2x.png';
import tech from './icn-tech@2x.png';
import techLg from './icn-tech-lg@2x.png';
import code from './icn-code@2x.png';
import codeLg from './icn-code-lg@2x.png';


const Root = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexPanelOuter = styled.div`
  @media only screen and (min-width: 501px) {
    display: flex;
  }
`;

const FlexPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 50vh;
  
  #about-icons {
    margin-right: 0;
  }
  
  @media only screen and (min-width: 501px) {
    height: 100vh;
    width: 50%;
    margin-right: 110px;
  }
`;

const RightPanelColor = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 150%;
  flex: 1;
  background-color: #363b42;
  
  @media only screen and (min-width: 501px) {
    right: -100%;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

const Text = styled.div`
  color: #b6b4b4;
  z-index: 1000;
  font-size: 28px;
  font-weight: bold;
  padding-left: 25px;
  
  @media only screen and (min-width: 501px) {
    padding-left: 76px;
    font-size: 36px;
  }
`;

const TextBox = styled.div`
  position: absolute;
  left: 25px;
  bottom: 0;
  height: 385px;
  width: 90%;

  h3 {
    font-size: 36px;
    color: white;
    margin-top: 0;
    margin-bottom: 20px;
  }

  p {
    font-size: 21px;
    line-height: 1.3;
  }

  img.contributionIcon {
    position: absolute;
    top: -220px;
    left: -20px;
  }
  
  @media only screen and (min-width: 501px) {
    left: 140px;
    width: 530px;
    
    img.contributionIcon {
      position: absolute;
      top: -350px;
    }
  }
`;

const LargeIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 1080px;
`;

const Panel4 = () => {
  const [aboutAnim, setAboutAnim] = useState(null)

  React.useEffect(() => {
    const aboutEle = document.querySelector('#about-icons');
    const anim = lottie.loadAnimation({
      container: aboutEle,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: 'lottie/data.json',
    });
    setAboutAnim(anim);
    anim.play()
  }, []);

  return (
    <StickyContainer>
      <Sticky>
        {(props) => {
          if (props.isSticky && !props.wasSticky) {
            aboutAnim && aboutAnim.goToAndPlay(0, true);
          }

          return (
            <Root style={props.style}>
              <Container>
                <FlexPanelOuter>
                  <FlexPanel>
                    <div id="about-icons" />
                  </FlexPanel>
                  <FlexPanel>
                    <Text>
                      We have
                      <span style={{color: '#ffffff'}}> three teams </span>
                      at BurnsRED, all with equally important contributions to the ideation process.
                    </Text>
                  </FlexPanel>
                </FlexPanelOuter>
              </Container>
              <RightPanelColor/>
            </Root>
          )
        }}
      </Sticky>
      <Sticky>
        {(props) => (
          <div style={props.style}>
            <Root>
              <div style={{
                backgroundColor: '#eb0b1a',
                height: '100vh',
                width: '100%',
                position: 'absolute',
                left: Math.max((120 + props.distanceFromTop / props.calculatedHeight * 100), 0) + '%'
              }}>
                <LargeIcon src={brainsLg} />
                <TextBox>
                  <img src={brains} style={{width: 213}} className="contributionIcon" />
                  <h3>Behavioural economics</h3>
                  <p>
                    We look at what motivations are at play for the successful resolution of a problem. What rewards,
                    nudges and framing can be used to maximise the positive outcomes and how can we best engage with
                    the users
                  </p>
                </TextBox>
              </div>
            </Root>
          </div>
        )}
      </Sticky>
      <Sticky>
        {(props) => (
          <div style={props.style}>
            <Root>
              <div style={{
                backgroundColor: 'white',
                height: '100vh',
                width: '100%',
                position: 'absolute',
                left: Math.max((220 + props.distanceFromTop / props.calculatedHeight * 100), 0) + '%'
              }}>
                <LargeIcon src={techLg} />
                <TextBox>
                  <img src={tech} style={{width: 213}} className="contributionIcon" />
                  <h3 style={{color: '#eb0b1a'}}>UX design</h3>
                  <p>
                    Human centred design to arrive at a simple and beautiful interpretation of the new process. Our
                    UX team are masters at making the complex appear simple and excel at crafting beautiful user
                    interfaces that make interacting with systems delightful.
                  </p>
                </TextBox>
              </div>
            </Root>
          </div>
        )}
      </Sticky>
      <Sticky>
        {(props) => (
          <div style={props.style}>
            <Root>
              <div style={{
                backgroundColor: '#eb0b1a',
                height: '100vh',
                width: '100%',
                position: 'absolute',
                left: Math.max((320 + props.distanceFromTop / props.calculatedHeight * 100), 0) + '%'
              }}>
                <LargeIcon src={codeLg} />
                <TextBox>
                  <img src={code} style={{width: 213}} className="contributionIcon" />
                  <h3>Technology delivery</h3>
                  <p>
                    The leading edge nature of the work we deliver attracts the very top tier of software development
                    talent from around the country. Our customers provide us with unique challenges to solve and the
                    technology delivery team apply their experience to deliver the highest standards of security and
                    maintainability.
                  </p>
                </TextBox>
              </div>
            </Root>
          </div>
        )}
      </Sticky>
      <div style={{height: '100vh'}} />
    </StickyContainer>
  )
}
export default Panel4;
