import styled from "styled-components";
import {Container} from "../shared";
import logo from './br-logo.png';
import navIcon from './nav-icon.svg';
import 'react-modern-drawer/dist/index.css';


const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  z-index: 10000;
  
  background-color: white;
`;

const StyledHeaderInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  > img {
    cursor: pointer;
  }
`;

const Header = ({onClickMenu}) => {
  return (
    <StyledHeader>
      <Container>
        <StyledHeaderInner>
          <img src={logo} width="152px"/>
          <img src={navIcon} width="26px" onClick={onClickMenu}/>
        </StyledHeaderInner>
      </Container>
    </StyledHeader>
  )
}

export default Header;
