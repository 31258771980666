import styled from "styled-components";
import {Container} from "../shared";
import {Link} from "react-scroll";

const Root = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 42px;
  padding-left: 100px;
  padding-right: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9900;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: bold;
  opacity: 0.3;
`;


const Banner = () => (
  <Root>
    <Container>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      </div>
    </Container>
  </Root>
)

export default Banner;
