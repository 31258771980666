import styled from "styled-components";
import { Container } from "../shared";

const Root = styled.div`
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 150px;

  display: flex;
  justify-content: center;
  background-color: white;
`;

const Text = styled.div`
  z-index: 1000;
  font-size: 50px;
  font-weight: bold;
`;

const Paragraph = styled.div`
  z-index: 1000;
  font-size: 15px;
  margin-top: 60px;
  max-width: 790px;
  line-height: 1.4;
`;

const BoxPanel = styled.div`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 200px;
  height: 240px;
  margin: 20px;
  padding: 14px;
  border: solid 6px #000;
  transition: all 0.3s ease;

  h2 {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
  }

  > a {
    position: absolute;
    left: 14px;
    bottom: 14px;
  }

  &:hover {
    border-color: #ff1b2a;
    color: #ff1b2a;

    > a {
       background-color: #ff1b2a;
    }
  }
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  font-size: 17px;
  font-weight: 600;
  color: white;
  width: 120px;
  height: 50px;
  border-radius: 8px;
  background-color: black;
`;

const Panel1 = () => (
  <Root>
    <Container>
      <Text>
        Want to solve
        <span style={{ color: '#eb0b1a' }}> interesting </span>
        problems?
      </Text>
      <Paragraph>
        We love improved outcomes and experiences for our customer’s customers with a flexible working environment and
        a totally flat structure with NO TIME SHEETS!
      </Paragraph>
      <div style={{ display: 'flex', flexFlow: 'wrap', margin: '40px -20px' }}>
        <BoxPanel>
          <h2>Front End Specialist</h2>
          <Button
            href="assets/BurnsRED Front End Specialist.pdf"
            target="_blank"
          >
            Join us
          </Button>
        </BoxPanel>
        <BoxPanel>
          <h2>Digital Business Analyst</h2>
          <Button
            href="assets/BurnsRED Digital Business Analyst.pdf"
            target="_blank"
          >
            Join us
          </Button>
        </BoxPanel>
        <BoxPanel>
          <h2>UX Designer</h2>
          <Button
            href="assets/BurnsRED UX Designer.pdf"
            target="_blank"
          >
            Join us
          </Button>
        </BoxPanel>
        <BoxPanel>
          <h2>Full Stack Developer</h2>
          <Button
            href="assets/BurnsRED Full Stack Developer.pdf"
            target="_blank"
          >
            Join us
          </Button>
        </BoxPanel>
        <BoxPanel>
          <h2>Junior Full Stack Developer</h2>
          <Button
              href="assets/BurnsRED Junior Full Stack Developer.pdf"
              target="_blank"
          >
            Join us
          </Button>
        </BoxPanel>
      </div>
    </Container>
  </Root>
)

export default Panel1;
